import { ServiceData, StatusWatcherData } from "../statusWatcher";

type Status = "yes" | "no" | "maybe";

async function displayStatus() {
	const globalStatusEl = document.getElementById("global-status");
	let status: StatusWatcherData;
	try {
		const res = await fetch("/api");
		status = await res.json() as StatusWatcherData;
	} catch (e) {
		globalStatusEl.classList.remove("hide");
		globalStatusEl.innerText = "Failed to fetch status";
		return;
	}
	globalStatusEl.classList.add("hide");

	const maxUpdateAge = 2 * 60 * 1000;
	const trailingDelay = 36 * 60 * 60 * 1000;
	setRowStatus("api-row", status.api, maxUpdateAge, 3 * 60 * 1000);
	setRowStatus("archiver-row", status.archiver, maxUpdateAge, 1.5 * 60 * 1000);
	setRowStatus("archiver-trailing-row", status.archiverTrailing, maxUpdateAge, trailingDelay + maxUpdateAge, trailingDelay - maxUpdateAge);
	setRowStatus("id-notifier-row", status.idNotifier, maxUpdateAge, 30 * 1000);
	setRowStatus("trailing-id-notifier-row", status.trailingIdNotifier, maxUpdateAge, trailingDelay + maxUpdateAge, trailingDelay - maxUpdateAge);

	const hasClientsEl = document.querySelector(".sub-status.has-clients .sub-status-value");
	if (status.archiver.hasClients)
		hasClientsEl.className = "sub-status-value yes";
	else
		hasClientsEl.className = "sub-status-value no";
}

function setRowStatus(rowId: string, rowStatus: ServiceData, maxUpdateAge: number, maxThingAge: number, minThingAge: number = 0) {
	const row = document.getElementById(rowId);

	let availabilityStatus: Status;
	if (!rowStatus.isAvailable)
		availabilityStatus = "no";
	else if (!isUpToDate(rowStatus.lastUpdateAt, maxUpdateAge))
		availabilityStatus = "maybe";
	else
		availabilityStatus = "yes";
	
	const availabilityEl = row.querySelector(".is-available");
	if (availabilityEl) {
		setElementStatusClass(availabilityEl, availabilityStatus);
	}

	const mostRecentDates = [rowStatus.mostRecentPostAt, rowStatus.mostRecentCommentAt];
	for (let i = 0; i < mostRecentDates.length; i++) {
		const mostRecentDate = mostRecentDates[i];
		const mostRecentEl = row.querySelectorAll(".most-recent")[i] as HTMLElement;
		let cellStatus: Status;
		if (isUpToDate(mostRecentDate, maxThingAge, minThingAge)) {
			if (availabilityStatus == "no")
				cellStatus = "maybe";
			else
				cellStatus = "yes";
		}
		else {
			cellStatus = "no";
		}
		setElementStatusClass(mostRecentEl, cellStatus);
	}
}

function setElementStatusClass(el: Element, status: Status) {
	const allStates = [
		"yes", "no", "maybe"
	];
	for (const possibleStatus of allStates) {
		if (possibleStatus == status)
			el.classList.add(status);
		else
			el.classList.remove(possibleStatus);
	}
}

function isUpToDate(date: number, maxAge: number, minAge: number = 0) {
	return Date.now() - date < maxAge && Date.now() - date > minAge;
}

window.addEventListener("load", () => {
	displayStatus();
	setInterval(displayStatus, 1000 * 30);
});
